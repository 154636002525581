.title{
    font-size:3.5rem;
    font-family: "Assistant";
    text-align: center;
    margin:1% auto;
    text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
    direction:rtl
}
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;   
    direction: rtl;
    flex-wrap: wrap;
    margin:3% auto;
}
.description{
    font-size:1.5rem;
    line-height: 1.2;
    font-family: "AssistantR";
    text-align: center;
    margin: 2% auto;
    direction:rtl
    }

    .more{
        font-size:2.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
        direction:rtl
    }
    .ul {
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; /* ממקם את הרשימה במרכז */
        margin: 2% auto;
        list-style-position: inside; /* מוודא שהנקודות מופיעות בתוך הרשימה */
        padding: 0;
        line-height: 1.5;
    }
    
    .li {
        font-size: 1.4rem;
     
        font-family: "Assistant";
        text-align: right; /* מיישר את הטקסט בצד ימין */
        width: 100%; /* מוודא שהרוחב הוא 100% כדי שהטקסט יהיה מיושר */
        margin: 1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
        direction: rtl; /* קובע את הכיוון מימין לשמאל */
    }
    .center{
        display: flex;
        justify-content: center;
        width:30%;
        margin:2% auto;
    }
    .image{
        object-fit: contain;
        width:100%;
    }

    @media only screen and (max-width: 350px){
        .title{
            font-size:2rem;
            font-family: "Assistant";
            text-align: center;
            margin:1% auto;
            text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
            direction:rtl
        }
        .row{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;   
            direction: rtl;
            margin:3% auto;
        }
        .description{
            font-size:1.1rem;
            line-height: 1.2;
            font-family: "AssistantR";
            text-align: center;
            margin: 2% auto;
            width:90%;
            direction:rtl
            }
        
            .more{
                font-size:1.5rem;
                font-family: "Assistant";
                text-align: center;
                margin:1% auto;
                text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
                direction:rtl
            }
            .ul {
                width: 90%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center; /* ממקם את הרשימה במרכז */
                margin: 2% auto;
                list-style: none; /* מסיר את הסגנון של רשימת התבליטים הדיפולטיבית */
                padding: 0;
            }
            
            .li {
                font-size: 1.1rem;
                font-family: "Assistant";
                text-align: right; /* מיישר את הטקסט למרכז */
                width: 90%; /* מוודא שהרוחב הוא 90% כדי שהטקסט יהיה מיושר */
                margin: 4% auto;
                text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
                direction: rtl; /* קובע את הכיוון מימין לשמאל */
                position: relative;
                padding-right: 20px; /* משאיר מקום לנקודה */
            }
            .center{
                display: flex;
                justify-content: center;
                width:90%;
                margin:2% auto;
            }
            .image{
                object-fit: contain;
                width:100%;
            }
    }
    @media only screen  and (min-width: 350px) and (max-width: 450px){
        .title{
            font-size:2.2rem;
            font-family: "Assistant";
            text-align: center;
            margin:1% auto;
            text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
            direction:rtl
        }
        .row{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;   
            direction: rtl;
            margin:3% auto;
        }
        .description{
            font-size:1.2rem;
            line-height: 1.2;
            font-family: "AssistantR";
            text-align: center;
            margin: 2% auto;
            width:90%;
            direction:rtl
            }
        
            .more{
                font-size:1.5rem;
                font-family: "Assistant";
                text-align: center;
                margin:1% auto;
                text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
                direction:rtl
            }
            .ul {
                width: 90%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center; /* ממקם את הרשימה במרכז */
                margin: 2% auto;
                list-style: none; /* מסיר את הסגנון של רשימת התבליטים הדיפולטיבית */
                padding: 0;
            }
            
            .li {
                font-size: 1.1rem;
                font-family: "Assistant";
                text-align: right; /* מיישר את הטקסט למרכז */
                width: 90%; /* מוודא שהרוחב הוא 90% כדי שהטקסט יהיה מיושר */
                margin: 4% auto;
                text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
                direction: rtl; /* קובע את הכיוון מימין לשמאל */
                position: relative;
                padding-right: 20px; /* משאיר מקום לנקודה */
            }
            .center{
                display: flex;
                justify-content: center;
                width:90%;
                margin:2% auto;
            }
            .image{
                object-fit: contain;
                width:100%;
            }
    }
    @media only screen  and (min-width: 450px) and (max-width: 550px){
        .title{
            font-size:2.5rem;
            font-family: "Assistant";
            text-align: center;
            margin:1% auto;
            text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
            direction:rtl
        }
        .row{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;   
            direction: rtl;
            margin:3% auto;
        }
        .description{
            font-size:1.25rem;
            line-height: 1.2;
            font-family: "AssistantR";
            text-align: center;
            margin: 2% auto;
            width:90%;
            direction:rtl
            }
        
            .more{
                font-size:1.5rem;
                font-family: "Assistant";
                text-align: center;
                margin:1% auto;
                text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
                direction:rtl
            }
            .ul {
                width: 90%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center; /* ממקם את הרשימה במרכז */
                margin: 2% auto;
                list-style: none; /* מסיר את הסגנון של רשימת התבליטים הדיפולטיבית */
                padding: 0;
            }
            
            .li {
                font-size: 1.2rem;
                font-family: "Assistant";
                text-align: right; /* מיישר את הטקסט למרכז */
                width: 90%; /* מוודא שהרוחב הוא 90% כדי שהטקסט יהיה מיושר */
                margin: 4% auto;
                text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
                direction: rtl; /* קובע את הכיוון מימין לשמאל */
                position: relative;
                padding-right: 20px; /* משאיר מקום לנקודה */
            }
            .center{
                display: flex;
                justify-content: center;
                width:80%;
                margin:2% auto;
            }
            .image{
                object-fit: contain;
                width:100%;
            }
    }
    @media only screen  and (min-width: 550px) and (max-width: 650px){
        .title{
            font-size:2.8rem;
            font-family: "Assistant";
            text-align: center;
            margin:1% auto;
            text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
            direction:rtl
        }
        .row{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;   
            direction: rtl;
            margin:3% auto;
        }
        .description{
            font-size:1.25rem;
            line-height: 1.2;
            font-family: "AssistantR";
            text-align: center;
            margin: 2% auto;
            width:90%;
            direction:rtl
            }
        
            .more{
                font-size:1.6rem;
                font-family: "Assistant";
                text-align: center;
                margin:1% auto;
                width:70%;
                text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
                direction:rtl
            }
            .ul {
                width: 90%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center; /* ממקם את הרשימה במרכז */
                margin: 2% auto;
                list-style: none; /* מסיר את הסגנון של רשימת התבליטים הדיפולטיבית */
                padding: 0;
            }
            
            .li {
                font-size: 1.25rem;
                font-family: "Assistant";
                text-align: right; /* מיישר את הטקסט למרכז */
                width: 90%; /* מוודא שהרוחב הוא 90% כדי שהטקסט יהיה מיושר */
                margin: 4% auto;
                text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
                direction: rtl; /* קובע את הכיוון מימין לשמאל */
                position: relative;
                padding-right: 20px; /* משאיר מקום לנקודה */
            }
            .center{
                display: flex;
                justify-content: center;
                width:80%;
                margin:2% auto;
            }
            .image{
                object-fit: contain;
                width:100%;
            }
    }
    @media only screen  and (min-width: 650px) and (max-width: 750px){
        .title{
            font-size:3rem;
            font-family: "Assistant";
            text-align: center;
            margin:1% auto;
            text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
            direction:rtl
        }
        .row{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;   
            direction: rtl;
            margin:3% auto;
        }
        .description{
            font-size:1.4rem;
            line-height: 1.2;
            font-family: "AssistantR";
            text-align: center;
            margin: 2% auto;
            width:90%;
            direction:rtl
            }
        
            .more{
                font-size:1.8rem;
                font-family: "Assistant";
                text-align: center;
                margin:1% auto;
                width:70%;
                text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
                direction:rtl
            }
            .ul {
                width: 70%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center; /* ממקם את הרשימה במרכז */
                margin: 2% auto;
                list-style: none; /* מסיר את הסגנון של רשימת התבליטים הדיפולטיבית */
                padding: 0;
            }
            
            .li {
                font-size: 1.4rem;
                font-family: "Assistant";
                text-align: right; /* מיישר את הטקסט למרכז */
                width: 90%; /* מוודא שהרוחב הוא 90% כדי שהטקסט יהיה מיושר */
                margin: 4% auto;
                text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
                direction: rtl; /* קובע את הכיוון מימין לשמאל */
                position: relative;
                padding-right: 20px; /* משאיר מקום לנקודה */
            }
            .center{
                display: flex;
                justify-content: center;
                width:70%;
                margin:2% auto;
            }
            .image{
                object-fit: contain;
                width:100%;
            }
    }  
      @media only screen  and (min-width: 750px) and (max-width: 950px){
        .title{
            font-size:3.2rem;
            font-family: "Assistant";
            text-align: center;
            margin:1% auto;
            text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
            direction:rtl
        }
        .row{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;   
            direction: rtl;
            margin:3% auto;
        }
        .description{
            font-size:1.4rem;
            line-height: 1.2;
            font-family: "AssistantR";
            text-align: center;
            margin: 2% auto;
            width:90%;
            direction:rtl
            }
        
            .more{
                font-size:2rem;
                font-family: "Assistant";
                text-align: center;
                margin:1% auto;
                width:70%;
                text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
                direction:rtl
            }
            .ul {
                width: 70%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center; /* ממקם את הרשימה במרכז */
                margin: 2% auto;
                list-style: none; /* מסיר את הסגנון של רשימת התבליטים הדיפולטיבית */
                padding: 0;
            }
            
            .li {
                font-size: 1.4rem;
                font-family: "Assistant";
                text-align: right; /* מיישר את הטקסט למרכז */
                width: 90%; /* מוודא שהרוחב הוא 90% כדי שהטקסט יהיה מיושר */
                margin: 4% auto;
                text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
                direction: rtl; /* קובע את הכיוון מימין לשמאל */
                position: relative;
                padding-right: 20px; /* משאיר מקום לנקודה */
            }
            .center{
                display: flex;
                justify-content: center;
                width:60%;
                margin:2% auto;
            }
            .image{
                object-fit: contain;
                width:100%;
            }
    }
    @media only screen  and (min-width: 950px) and (max-width: 1150px){
        .title{
            font-size:3.5rem;
            font-family: "Assistant";
            text-align: center;
            margin:1% auto;
            text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
            direction:rtl
        }
        .row{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;   
            direction: rtl;
            margin:3% auto;
        }
        .description{
            font-size:1.5rem;
            line-height: 1.2;
            font-family: "AssistantR";
            text-align: center;
            margin: 2% auto;
            width:90%;
            direction:rtl
            }
        
            .more{
                font-size:2.1rem;
                font-family: "Assistant";
                text-align: center;
                margin:1% auto;
                width:70%;
                text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
                direction:rtl
            }
            .ul {
                width: 70%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center; /* ממקם את הרשימה במרכז */
                margin: 2% auto;
                list-style: none; /* מסיר את הסגנון של רשימת התבליטים הדיפולטיבית */
                padding: 0;
            }
            
            .li {
                font-size: 1.5rem;
                font-family: "Assistant";
                text-align: right; /* מיישר את הטקסט למרכז */
                width: 90%; /* מוודא שהרוחב הוא 90% כדי שהטקסט יהיה מיושר */
                margin: 4% auto;
                text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
                direction: rtl; /* קובע את הכיוון מימין לשמאל */
                position: relative;
                padding-right: 20px; /* משאיר מקום לנקודה */
            }
            .center{
                display: flex;
                justify-content: center;
                width:50%;
                margin:2% auto;
            }
            .image{
                object-fit: contain;
                width:100%;
            }
    }
    @media only screen  and (min-width: 1150px) and (max-width: 1350px){
        .title{
            font-size:3.5rem;
            font-family: "Assistant";
            text-align: center;
            margin:1% auto;
            text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
            direction:rtl
        }
        .row{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;   
            direction: rtl;
            flex-wrap: wrap;
            margin:3% auto;
        }
        .description{
            font-size:1.5rem;
            line-height: 1.2;
            font-family: "AssistantR";
            text-align: center;
            margin: 2% auto;
            direction:rtl
            }
        
            .more{
                font-size:2.5rem;
                font-family: "Assistant";
                text-align: center;
                margin:1% auto;
                text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
                direction:rtl
            }
            .ul {
                width: 80%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center; /* ממקם את הרשימה במרכז */
                margin: 2% auto;
                list-style: none; /* מסיר את הסגנון של רשימת התבליטים הדיפולטיבית */
                padding: 0;
            }
            
            .li {
                font-size: 1.4rem;
                font-family: "Assistant";
                text-align: right; /* מיישר את הטקסט למרכז */
                width: 50%; /* מוודא שהרוחב הוא 90% כדי שהטקסט יהיה מיושר */
                margin: 2% auto;
                text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
                direction: rtl; /* קובע את הכיוון מימין לשמאל */
                position: relative;
                padding-right: 20px; /* משאיר מקום לנקודה */
            }
            .center{
                display: flex;
                justify-content: center;
                width:40%;
                margin:2% auto;
            }
            .image{
                object-fit: contain;
                width:100%;
            }
    }
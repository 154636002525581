.name{
    font-size:4rem;
    font-family: "Assistant";
    text-align: center;
    margin:1% auto;
    text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.4);
    direction:rtl
}
.row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin:1% auto;
    justify-content: space-evenly;
}
.what{
    font-size:2.5rem;
    font-family: "Assistant";
    text-align: center;
    margin:1% auto;
    direction: rtl;
    text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.4);
    width:40%;
}
.more{
    font-size:2rem;
    font-family: "Assistant";
    text-align: center;
    margin:1% auto;
    direction: rtl;
    text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.4);
    width:40%;
}

.description{
font-size:1.5rem;
line-height: 1.2;
font-family: "AssistantR";
text-align: center;
margin: 2% auto;
direction:rtl
}
.center{
    display: flex;
    margin:2% auto;
    width:20%;
    justify-content: center;
}
.image{
    object-fit: contain;
    width:100%;
    border-radius:50%;
    box-shadow: 0 0 5px 1px rgba(57, 57, 57, 0.345);
}
@media only screen and (max-width: 350px){
    .name{
        font-size:2.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.4);
        direction:rtl
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:1% auto;
        justify-content: space-evenly;
    }
    .what{
        font-size:1.4rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
        width:95%;
    }
    .more{
        font-size:1.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
        width:90%;
    }
    
    .description{
    font-size:1.15rem;
    line-height: 1.2;
    font-family: "AssistantR";
    text-align: center;
    margin: 3% auto;
    width:95%;
    direction:rtl
    }
    .center{
        display: flex;
        margin:3% auto;
        width:70%;
        justify-content: center;
    }
    .image{
        object-fit: contain;
        width:100%;
        border-radius:50%;
        box-shadow: 0 0 5px 1px rgba(8, 8, 8, 0.345);
    }
}
@media only screen and (min-width: 350px) and (max-width: 450px){
    .name{
        font-size:2.8rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.4);
        direction:rtl
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:1% auto;
        justify-content: space-evenly;
    }
    .what{
        font-size:1.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
        width:95%;
    }
    .more{
        font-size:1.6rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
        width:90%;
    }
    
    .description{
    font-size:1.2rem;
    line-height: 1.2;
    font-family: "AssistantR";
    text-align: center;
    margin: 3% auto;
    width:95%;
    direction:rtl
    }
    .center{
        display: flex;
        margin:3% auto;
        width:60%;
        justify-content: center;
    }
    .image{
        object-fit: contain;
        width:100%;
        border-radius:50%;
        box-shadow: 0 0 5px 1px rgba(8, 8, 8, 0.345);
    }
}
@media only screen and (min-width: 450px) and (max-width: 550px){
    .name{
        font-size:3rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.4);
        direction:rtl
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:1% auto;
        justify-content: space-evenly;
    }
    .what{
        font-size:1.6rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
        width:95%;
    }
    .more{
        font-size:1.7rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
        width:90%;
    }
    
    .description{
    font-size:1.25rem;
    line-height: 1.2;
    font-family: "AssistantR";
    text-align: center;
    margin: 3% auto;
    width:95%;
    direction:rtl
    }
    .center{
        display: flex;
        margin:3% auto;
        width:50%;
        justify-content: center;
    }
    .image{
        object-fit: contain;
        width:100%;
        border-radius:50%;
        box-shadow: 0 0 5px 1px rgba(8, 8, 8, 0.345);
    }
}
@media only screen and (min-width: 550px) and (max-width: 650px){
    .name{
        font-size:3.2rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.4);
        direction:rtl
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:1% auto;
        justify-content: space-evenly;
    }
    .what{
        font-size:1.7rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
        width:95%;
    }
    .more{
        font-size:1.8rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
        width:90%;
    }
    
    .description{
    font-size:1.25rem;
    line-height: 1.2;
    font-family: "AssistantR";
    text-align: center;
    margin: 3% auto;
    width:65%;
    direction:rtl
    }
    .center{
        display: flex;
        margin:3% auto;
        width:40%;
        justify-content: center;
    }
    .image{
        object-fit: contain;
        width:100%;
        border-radius:50%;
        box-shadow: 0 0 5px 1px rgba(8, 8, 8, 0.345);
    }
}
@media only screen and (min-width: 650px) and (max-width: 950px){
    .name{
        font-size:3.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.4);
        direction:rtl
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:1% auto;
        justify-content: space-evenly;
    }
    .what{
        font-size:1.8rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
        width:95%;
    }
    .more{
        font-size:1.9rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
        width:90%;
    }
    
    .description{
    font-size:1.4rem;
    line-height: 1.2;
    font-family: "AssistantR";
    text-align: center;
    margin: 3% auto;
    width:65%;
    direction:rtl
    }
    .center{
        display: flex;
        margin:3% auto;
        width:35%;
        justify-content: center;
    }
    .image{
        object-fit: contain;
        width:100%;
        border-radius:50%;
        box-shadow: 0 0 5px 1px rgba(8, 8, 8, 0.345);
    }
}
@media only screen and (min-width: 950px) and (max-width: 1350px){
    .name{
        font-size:3.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.4);
        direction:rtl
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:1% auto;
        justify-content: space-evenly;
    }
    .what{
        font-size:2rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
        width:95%;
    }
    .more{
        font-size:2.1rem;
        font-family: "Assistant";
        text-align: center;
        margin:2% auto;
        direction: rtl;
        text-shadow: 1px 2px 2px rgba(120, 120, 120, 0.2);
        width:90%;
    }
    
    .description{
    font-size:1.5rem;
    line-height: 1.2;
    font-family: "AssistantR";
    text-align: center;
    margin: 3% auto;
    width:45%;
    direction:rtl
    }
    .center{
        display: flex;
        margin:3% auto;
        width:25%;
        justify-content: center;
    }
    .image{
        object-fit: contain;
        width:100%;
        border-radius:50%;
        box-shadow: 0 0 5px 1px rgba(8, 8, 8, 0.345);
    }
}

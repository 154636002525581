.bubble {
  width: 300px;
  height: 180px;
  background: linear-gradient(145deg, #4a2a1b, #381809);
border-radius: 50% 50px 50% 50px;
  position: relative;
  overflow: hidden;
  display: flex;
  margin:3% auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.501);
}
.number{

  font-size:1.5rem;
  font-family: "Assistant";
  text-align: center;
  margin:3% auto;
  direction:rtl;
  text-shadow: 1px 3px 2px rgba(245, 245, 245, 0.314);
  color: white;
  position: absolute;
  top:0;
  right:10%
}

.description{
  font-size:1rem;
  font-family: "AssistantR";
  text-align: center;
  margin:3% auto;
  direction:rtl;
  width:60%;
  text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
  color: white;
}
.icon{
  display: flex;
  justify-content: center;
  width:30%;
  margin:0 auto;
}
@media only screen and (max-width: 350px){

  .bubble {
    width: 280px;
    height: 170px;
    background: linear-gradient(145deg, #4a2a1b, #381809);
  border-radius: 50% 50px 50% 50px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.501);
  }
  .number{
  
    font-size:1.4rem;
    font-family: "Assistant";
    text-align: center;
    margin:3% auto;
    direction:rtl;
    text-shadow: 1px 3px 2px rgba(245, 245, 245, 0.314);
    color: white;
    position: absolute;
    top:0;
    right:10%
  }
  
  .description{
    font-size:1rem;
    font-family: "AssistantR";
    text-align: center;
    margin:3% auto;
    direction:rtl;
    width:60%;
    text-shadow: 1px 1px 1px rgba(245, 245, 245, 0.158);
    color: white;
  }
  .icon{
    display: flex;
    justify-content: center;
    width:30%;
    margin:0 auto;
  }


}
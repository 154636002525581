.title{
    font-size:3.5rem;
    font-family: "Assistant";
    text-align: center;
    margin:1% auto;
    text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
    direction:rtl
}
.description{
    font-size:1.5rem;
    font-family: "AssistantR";
    text-align: center;
    margin:1% auto;
    text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.1);
    direction:rtl
}
.more{
    font-size:2.5rem;
    font-family: "Assistant";
    text-align: center;
    margin:1% auto;
    text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
    direction:rtl
}
@media only screen and (max-width: 350px){
    .title{
        font-size:1.6rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
        direction:rtl
    }
    .description{
        font-size:1.2rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.1);
        direction:rtl
    }
    .more{
        font-size:1.2rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
        direction:rtl
    }
}
@media only screen and (min-width: 350px) and (max-width: 450px) {
    .title{
        font-size:1.8rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
        direction:rtl
    }
    .description{
        font-size:1.2rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.1);
        direction:rtl
    }
    .more{
        font-size:1.25rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
        direction:rtl
    }
}
@media only screen and (min-width: 450px) and (max-width: 550px) {
    .title{
        font-size:2rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
        direction:rtl
    }
    .description{
        font-size:1.25rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.1);
        direction:rtl
    }
    .more{
        font-size:1.4rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
        direction:rtl
    }
}
@media only screen and (min-width: 550px) and (max-width: 650px) {
    .title{
        font-size:2.2rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
        direction:rtl
    }
    .description{
        font-size:1.25rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.1);
        direction:rtl
    }
    .more{
        font-size:1.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
        direction:rtl
    }
}
@media only screen and (min-width: 650px) and (max-width: 850px) {
    .title{
        font-size:2.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
        direction:rtl
    }
    .description{
        font-size:1.25rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.1);
        direction:rtl
    }
    .more{
        font-size:1.5rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
        direction:rtl
    }
}
@media only screen and (min-width: 850px) and (max-width: 1050px) {
    .title{
        font-size:2.7rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
        direction:rtl
    }
    .description{
        font-size:1.4rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.1);
        direction:rtl
    }
    .more{
        font-size:1.6rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
        direction:rtl
    }
}
@media only screen and (min-width: 1050px) and (max-width: 1250px) {
    .title{
        font-size:3rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
        direction:rtl
    }
    .description{
        font-size:1.5rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.1);
        direction:rtl
    }
    .more{
        font-size:1.8rem;
        font-family: "Assistant";
        text-align: center;
        margin:1% auto;
        text-shadow: 1px 4px 2px rgba(120, 120, 120, 0.2);
        direction:rtl
    }
}